
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Toast',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      isShowToast: false,
      timeId: 0,
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.isShowToast = val;
        clearTimeout(this.timeId);
        this.timeId = setTimeout(() => {
          this.hideToast();
        }, this.duration);
      },
      immediate: true,
    },
  },
  methods: {
    hideToast() {
      this.isShowToast = false;
      this.$emit('update:visible', false);
    },
  },
});
